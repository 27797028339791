import "./overline.scss";

import cn from "clsx";
import { EditAttributes } from "design-system/types/types";
import { Link } from "design-system/components/primitives/link/link";

export interface OverlineProps {
  text?: string;
  link?: string;
  className?: string;

  editAttributes?: {
    text?: EditAttributes;
  };
}

export function Overline({
  text,
  link,
  className,
  editAttributes,
}: OverlineProps) {
  const overlineClass = cn("hbs-overline", className);

  if (text && link) {
    return (
      <Link className={overlineClass} href={link} {...editAttributes?.text}>
        {text}
      </Link>
    );
  }

  return <span className={overlineClass}>{text}</span>;
}
