import "./split-topper.scss";
// IG: global variant issue hbs-article-topper__overline
import "design-system/components/toppers/article-topper/article-topper.scss";

import { ReactNode } from "react";
import cn from "clsx";
import { BaseArticleTopperProps } from "design-system/components/toppers/article-topper/article-topper";
import { Byline } from "design-system/components/primitives/byline/byline";
import {
  CtaList,
  CtaListProps,
} from "design-system/components/primitives/cta-list/cta-list";
import {
  CtaLink,
  CtaLinkProps,
} from "design-system/components/primitives/cta-link/cta-link";
import { MediaAsset } from "design-system/components/primitives/media-asset/media-asset";
import { Overline } from "design-system/components/primitives/overline/overline";
import {
  ExpandedPalette,
  expandedPalette,
  getSplitTopperTheme,
} from "design-system/utils/theme";
import { shouldDecreaseTextSize } from "design-system/utils/text";
import {
  SearchBoxDropdown,
  SearchBoxDropdownProps,
} from "design-system/components/search/search-dropdown/search-dropdown";
import { Link } from "design-system/components/primitives/link/link";
import { EditAttributes } from "design-system/types/types";
import { Icon } from "design-system/components/icons/icon";

export interface SplitTopperProps extends BaseArticleTopperProps {
  ctaButton?: CtaLinkProps;
  ctaList?: CtaListProps;
  variant?: "default" | "article" | "bulletin";
  isPodcast?: boolean;
  spotTheme?: ExpandedPalette;
  customBgColor?: string;
  searchDropdown?: SearchBoxDropdownProps;
  ctaLabel?: string;
  mediaLink?: string;
  mediaHighlightCaption?: ReactNode;
  hiddenTitle?: string;

  editAttributes?: {
    title?: EditAttributes;
    subtitle?: EditAttributes;
    mediaHighlightCaption?: EditAttributes;
  };
}

/**
 * ## See it in use on...
 * - An [article page](/story/example-pages-article-pages-split-topper-with-blue-4-theme--story)
 *
 *  - **`id: CT-002-004-00`**
 *  - **`data-region: ct__article__split`**
 *
 * - A [landing page](/example-pages-landing-pages-split-topper-with-dark-theme--story)
 *
 *  - **`id: CT-001-004-00`**
 *  - **`data-region: ct__page__split`**
 */
export function SplitTopper({
  ctaButton,
  ctaList,
  byline,
  variant = "default",
  isPodcast,
  mediaAsset,
  overline,
  subtitle,
  theme,
  spotTheme = "teal4",
  customBgColor,
  title,
  hiddenTitle,
  searchDropdown,
  ctaLabel,
  mediaLink,
  mediaHighlightCaption,
  editAttributes,
}: SplitTopperProps) {
  if (mediaAsset?.image) {
    mediaAsset.image.loading = "eager";
    mediaAsset.image.mobileWidth = 460;
  }
  if (mediaAsset?.kaltura) {
    mediaAsset.kaltura.withModal = true;
    mediaAsset.aspectRatio = "1/1";
  }

  const articleTopperTheme = getSplitTopperTheme(spotTheme);
  const HeadingLevel = hiddenTitle ? "div" : "h1";

  const titleClass = cn(
    shouldDecreaseTextSize(title, 55, 24)
      ? "hbs-split-topper__title--xl"
      : shouldDecreaseTextSize(title, 20, 12)
        ? "hbs-split-topper__title--long"
        : "hbs-split-topper__title--short",
  );

  const articleContent = (
    <div className="hbs-split-topper__content-child--article">
      {overline && (
        <Overline className="hbs-article-topper__overline" {...overline} />
      )}

      {title && (
        <h1
          {...editAttributes?.title}
          className={`hbs-split-topper__title--article ${titleClass}`}
        >
          {title}
        </h1>
      )}

      {byline && (
        <div className="hbs-split-topper__meta">
          <Byline {...byline} />
        </div>
      )}

      {subtitle && (
        <div
          {...editAttributes?.subtitle}
          className="hbs-split-topper__excerpt"
        >
          {subtitle}
        </div>
      )}
    </div>
  );

  const defaultContent = (
    <div className="hbs-split-topper__content-child">
      {hiddenTitle && (
        <h1 className="hbs-global-visually-hidden">{hiddenTitle}</h1>
      )}
      {title && (
        <HeadingLevel
          {...editAttributes?.title}
          className={`hbs-split-topper__title ${titleClass}`}
        >
          {title}
        </HeadingLevel>
      )}

      {searchDropdown && (
        <SearchBoxDropdown
          {...searchDropdown}
          placeholder={
            searchDropdown?.placeholder ?? "Search content and resources"
          }
        />
      )}

      {subtitle && !searchDropdown && (
        <div
          {...editAttributes?.subtitle}
          className="hbs-split-topper__subtitle"
        >
          {subtitle}
        </div>
      )}

      {(ctaList || ctaButton) && (
        <div className="hbs-split-topper__cta">
          {ctaList && !ctaButton && (
            <CtaList {...ctaList} label={ctaLabel} HeadingLevel="h2" />
          )}
          {ctaButton && (
            <CtaLink
              className="hbs-split-topper__cta-button"
              type="primary-button"
              href={ctaButton.href}
            >
              {ctaButton.children}
            </CtaLink>
          )}
        </div>
      )}
    </div>
  );

  const bulletinContent = (
    <div className="hbs-split-topper__content-child">
      {overline && (
        <Overline className="hbs-article-topper__overline" {...overline} />
      )}

      {title && (
        <h1
          {...editAttributes?.title}
          className={`hbs-split-topper__title ${titleClass}`}
        >
          {title}
        </h1>
      )}

      {searchDropdown && (
        <SearchBoxDropdown
          {...searchDropdown}
          placeholder={
            searchDropdown?.placeholder ?? "Search content and resources"
          }
        />
      )}

      {subtitle && !searchDropdown && (
        <div
          {...editAttributes?.subtitle}
          className="hbs-split-topper__subtitle"
        >
          {subtitle}
        </div>
      )}

      {(ctaList || ctaButton) && (
        <div className="hbs-split-topper__cta">
          {ctaList && !ctaButton && <CtaList {...ctaList} label={ctaLabel} />}
          {ctaButton && (
            <CtaLink
              className="hbs-split-topper__cta-button"
              type="primary-button"
              href={ctaButton.href}
            >
              {ctaButton.children}
            </CtaLink>
          )}
        </div>
      )}
    </div>
  );

  const mediaHighlightImage = mediaAsset?.image && (
    <MediaAsset
      image={{
        ...mediaAsset.image,
        caption: mediaHighlightCaption ? (
          <div
            {...editAttributes?.mediaHighlightCaption}
            className="hbs-split-topper__media-highlight"
          >
            {mediaHighlightCaption}
            {mediaLink && (
              <Icon name="arrow" className="hbs-split-topper__link-arrow" />
            )}
          </div>
        ) : null,
      }}
    />
  );

  const mediaHighlightContent = (
    <div
      className={cn(
        isPodcast
          ? "hbs-split-topper__media-podcast"
          : "hbs-split-topper__media",
      )}
      style={
        variant === "bulletin" && customBgColor
          ? {
              color: customBgColor,
            }
          : variant === "article" || variant === "bulletin"
            ? { color: expandedPalette[spotTheme] }
            : {}
      }
    >
      {mediaLink ? (
        <Link className="hbs-split-topper__media-link" href={mediaLink}>
          {mediaHighlightImage}
        </Link>
      ) : (
        mediaHighlightImage
      )}
    </div>
  );

  return (
    <div
      className={cn(
        `hbs-split-topper-wrapper`,
        `hbs-split-topper-wrapper--${
          variant === "article" ? "article" : "page"
        }`,
        (variant === "article" || variant === "bulletin") &&
          `hbs-split-topper-wrapper--spot-theme`,
        Boolean(searchDropdown) && `hbs-split-topper-wrapper--search`,
        "hbs-topper-wrapper",
      )}
      data-theme={
        variant === "article" || (variant === "bulletin" && !customBgColor)
          ? articleTopperTheme
          : theme
      }
      data-region={`ct__${variant === "article" ? "article" : "page"}__split`}
    >
      <div className="hbs-split-topper">
        {/* Content */}
        <div className="hbs-split-topper__content">
          {variant === "article"
            ? articleContent
            : variant === "bulletin"
              ? bulletinContent
              : defaultContent}
        </div>

        {/* Media */}
        {mediaHighlightCaption ? (
          mediaHighlightContent
        ) : (
          <div
            className={cn(
              isPodcast
                ? "hbs-split-topper__media-podcast"
                : "hbs-split-topper__media",
            )}
            style={
              variant === "bulletin" && customBgColor
                ? {
                    color: customBgColor,
                  }
                : variant === "article" || variant === "bulletin"
                  ? { color: expandedPalette[spotTheme] }
                  : {}
            }
          >
            {isPodcast ? (
              <MediaAsset
                hide={{ caption: true }}
                {...mediaAsset}
                aspectRatio="1/1"
              />
            ) : (
              <MediaAsset hide={{ caption: true }} {...mediaAsset} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
